(function() {
    
    $('[data-toggle="offcanvas"]').on('click', function () {
            var target = $(this).data('target');
            let pane = $(this).data('pane');
            let parent = $(this).data('parent');

            $(target).toggleClass('show');
            $(pane).toggleClass('in');
            $(parent).toggleClass('modal-open');
            // $(this).toggleClass('open');
        });
})();