import Blazy from 'blazy';

const blazy = new Blazy({
    breakpoints: [
        {
            width: 480,
            src: 'data-src-sm'
        },
        {
            width: 768,
            src: 'data-src-md'
        },
        {
            width: 992,
            src: 'data-src-lg'
        }
    ]
});

export default blazy;