import "../css/style.less";
import "matchheight";
import "../js/bootstrap";
import "../js/select";

import "../js/fontawesome";
import "../js/offcanvas";
import "../js/blazy";

import { ExternalLinks } from "./externallinks";
import { PageTimer } from "./pagetimer";

(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();

function gRecaptcha(response) {
    $('[data-parsley-recaptcha]').data('parsleyRecaptcha', 1);
}

let forms = document.getElementsByClassName('js-form-validate');
if(forms.length) {
        const honey = document.querySelectorAll('[data-website]')

        for(var i = 0; i < honey.length; i++)
        {
            let el = honey.item(i);
            el.value = el.dataset.website;
        }
        import(/* webpackChunkName: "forms" */ '../js/form')
            .then((Forms) => {
                let form =  new Forms.default(forms);
                form.submitButton();
                form.validate();
                form.bindEvents();
            })

}

let mapMarkers = document.querySelectorAll('[data-map-marker]');
if(mapMarkers.length) {

        import(/* webpackChunkName: "googlemap" */ '../js/googlemap')
            .then((GoogleMap) => {
                let googleMap =  new GoogleMap.default();
                googleMap.initialize()
            })

}

let shares = document.querySelectorAll('[data-share-default]');
if(shares.length) {

        import(/* webpackChunkName: "share" */ '../js/share')
            .then((ShareHandler) => {
                let socialShare =  new ShareHandler.default();
                socialShare.build()
            })

}

let popups = document.querySelectorAll('[data-mfp-type]');
if(popups.length) {

        import(/* webpackChunkName: "magnific" */ '../js/magnificpopup')
            .then((Magnific) => {
                let magnific =  new Magnific.default(popups);
                magnific.initialize()
            })

}
let slides = document.querySelectorAll('[data-slick]');
if(slides.length) {

        import(/* webpackChunkName: "slider" */ '../js/slider')
            .then(() => {
               console.log('found slides');
            })

}

let positions = document.querySelectorAll('[data-position]');
if (positions.length) {
    for(var i = 0; i < positions.length; i++)
        {
            let el = positions.item(i);
            let position  = el.dataset.position.split(',');
            el.style.backgroundPosition = position.join(' ');
        }

}


// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}