let tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
if(tooltips.length) {
    new Promise(() => {
        import(/* webpackChunkName: "bs-tooltip" */ 'bootstrap/js/tooltip')
        .then((Tooltip) => {
            for(var i = 0; i < tooltips.length; i++)
            {
                let tooltip = tooltips.item(i);
                new Tooltip.default(tooltip).tooltip();
            } 
        })
    }) 
}

let dropdowns = document.querySelectorAll('[data-toggle="dropdown"]');
if(dropdowns.length) {
    new Promise(() => {
        import(/* webpackChunkName: "bs-dropdown" */ 'bootstrap/js/dropdown')
        .then(() => {
            console.log('dropdowns found')
        })
    }) 
}
let modals = document.querySelectorAll('[data-toggle="modal"]');
if(modals.length) {
    new Promise(() => {
        import(/* webpackChunkName: "bs-modal" */ 'bootstrap/js/modal')
        .then(() => {
            console.log('modals found')
        })
    }) 
}

let tabs = document.querySelectorAll('[data-toggle="tab"]');
if(tabs.length) {
    new Promise(() => {
        import(/* webpackChunkName: "bs-tab" */ 'bootstrap/js/tab')
        .then(() => {
            console.log('tabs found')
        })
    }) 
}

let alerts = document.querySelectorAll('[data-dismiss="alert"]');
if(alerts.length) {
    new Promise(() => {
        import(/* webpackChunkName: "bs-alert" */ 'bootstrap/js/alert')
        .then(() => {
            console.log('alerts found')
        })
    }) 
}

let collapses = document.querySelectorAll('.collapse');
if(collapses.length) {
    new Promise(() => {
        import(/* webpackChunkName: "bs-collapse" */ 'bootstrap/js/collapse')
        .then(() => {
            console.log('collapse found')
        })
    }) 
}

