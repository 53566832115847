import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { faEdit as falEdit, faMobile as falMobile, faTimes as falTimes } from '@fortawesome/pro-light-svg-icons';
import { faSearch as farSearch, faRss as farRss, faShareAlt, faAngleLeft, faAngleRight, faEnvelope as farEnvelope, faGlobe as farGlobe, faMailbox, faMapMarkedAlt as farMapMarkedAlt, faPlayCircle as farPlayCircle, faBars as farBars, faChevronLeft as farChevronLeft, 
    faChevronRight as farChevronRight, 
    faHotel as farHotel,
    faMap as farMap
} from '@fortawesome/pro-regular-svg-icons';
import { 
    faCircle as fasCircle, 
    faSquare as fasSquare, 
    faRss as fasRss, 
    faShareAlt as fasShareAlt, 
    faEnvelopeSquare as fasEnvelopeSquare, 
    faPhoneAlt, faFax, 
    faPlusCircle as fasPlusCircle, 
    faMinusCircle as fasMinusCircle, 
    faMapMarkerAlt as fasMapMarkedAlt, 
    faPlayCircle as fasPlayCircle, 
    faChevronCircleLeft, faChevronCircleRight,
    faList
} from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faTwitter, faFacebookSquare, faTwitterSquare, faLinkedinIn, faYoutube, faPinterestP, faInstagram } from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

// light
library.add(falEdit, falMobile, falTimes, faList);
// regular
library.add(farSearch,farHotel, farRss, farMap, faShareAlt, faAngleLeft, faAngleRight, farEnvelope, farGlobe, faMailbox, farMapMarkedAlt, farPlayCircle, farBars, farChevronLeft, farChevronRight);
// solid
library.add(fasCircle, fasSquare, fasRss, fasShareAlt, fasEnvelopeSquare, faPhoneAlt, faFax, fasPlusCircle, fasMinusCircle, fasMapMarkedAlt, fasPlayCircle, faChevronCircleLeft, faChevronCircleRight);
// brands
library.add(faFacebookF, faTwitter, faFacebookSquare, faTwitterSquare, faLinkedinIn, faYoutube, faPinterestP, faInstagram);

dom.i2svg();
dom.watch();