
import "selectize";
(function() {

    var options = {
        maxItems: 1,
        valueField: 'page_url',
        labelField: 'title',
        searchField: ['title', 'summary'],
        onItemRemove: onItemRemove,
        hideSelected: true,
        preload: true,
        options: [],
        render: {
            option: function (item, escape) {
                return '<div>' +
                '<span class="title">' +
                '<span class="name">' + escape(item.title) + '</span>' +
                (item.channel ? '<span class="select-channel">' + escape(item.channel) + '</span>' : '') +
                '</span>' +
                '</div>';
            }
        },
        create: false
    };
    
    var activityOptions, destinationOptions;
    var searchForm = $('#search-form');
    var searchFormButton = searchForm.find('button');
    var activitySelect;
    var activitySelectize;
    var $activitySelect = $('[data-selectize="activity"]'); 
    var activityValue;
    var destinationSelect;
    var destinationSelectize;
    var $destinationSelect = $('[data-selectize="destination"]');
    var destinationValue;


    var activityOptions = $.extend({}, options, {
        options: [$activitySelect.data('selectizeOptions')],
        load: function (query, callback) {
            var self = this;
            var locationSelectize = destinationSelect[0].selectize;
            if (!query.length || locationSelectize.items.length) return callback();
            $.ajax({
                url: '/search/activities',
                dataType: "json",
                data: {
                    keywords: encodeURIComponent(query)
                },
                error: function () {
                    callback();
                },
                success: function (res) {
                    callback(callback(res));
                }
            });
        },
        onChange: function (value) {
            
            if (!value) {
                return;
            }
            
            var locationSelectize = destinationSelect[0].selectize,
            destValue = locationSelectize.items;
            
            var url = '/adventures/activities/' + value;
            var title = this.options[value].title;
            var valueId = this.options[value].id;
            
            console.log(title, valueId, 'activity');
            
            if (valueId === 'all' && !destValue.length) {
                url = '/adventures/' + value;
            } else if (destValue.length && valueId !== 'all') {
                var location = locationSelectize.options[locationSelectize.items[0]].channel_short.toLowerCase(),
                locationUrl = location.replace('_location', '');
                
                url = '/activities/' + locationUrl + '/' + value + '/' + locationSelectize.items[0];
                
                console.log(url, 'with destination');
            } else if (destValue.length) {
                var location = locationSelectize.options[locationSelectize.items[0]].channel_short.toLowerCase(),
                locationUrl = location.replace('_location', '');
                url = '/adventures/' + locationUrl + '/' + locationSelectize.items[0];
                console.log(url, 'without destination');
            }
            
            searchFormButton.data('action', url);
            
            locationSelectize.load(function (callback) {
                $.ajax({
                    url: '/search/activity-location',
                    dataType: "json",
                    data: {
                        name: encodeURIComponent(title)
                    },
                    error: function () {
                        callback();
                    },
                    success: function (res) {
                        locationSelectize.clear(true);
                        
                        callback(res);
                        
                        if (destValue.length) {
                            locationSelectize.addItem(destValue[0], true);
                        }
                        
                    }
                });
            });
            
        }
    });
    var destinationOptions = $.extend({}, options, {
        load: function (query, callback) {
            if (!query.length) return callback();
            $.ajax({
                url: '/search/locations',
                dataType: "json",
                data: {
                    keywords: encodeURIComponent(query)
                },
                error: function () {
                    callback();
                },
                success: function (res) {
                    callback(res);
                }
            });
        },
        options: [$destinationSelect.data('selectizeOptions')],
        onChange: function (value) {
            
            console.log('change dest:', value);
            if (!value) {
                searchFormButton.data('action', '');
                return;
            }
            
            activityValue = activitySelectize.items.length ?  activitySelectize.items[0] : activityValue;
            
            var location = this.options[value].channel_short.toLowerCase(),
            locationUrl = location.replace('_location', ''),
            channel = this.options[value].channel_short,
            title = this.options[value].title,
            url = '/adventures/' + locationUrl + '/' + value;
            
            
            if (activityValue) {
                // url = '/activities/' + locationUrl + '/' + activityValue + '/' + value;
            }
            
            searchFormButton.data('action', url);
            
            console.log('Destination:', title, 'activity value', activityValue, url);
            
            activitySelectize.load(function (callback) {
                
                $.ajax({
                    url: '/search/location-activities',
                    dataType: "json",
                    data: {
                        name: encodeURIComponent(title),
                        channel: channel
                    },
                    beforeSend: function () {
                        if (activitySelectize.options[activityValue]) {
                            activitySelectize.settings.placeholder = activitySelectize.options[activityValue].title;
                            activitySelectize.updatePlaceholder();
                            
                            activitySelectize.refreshItems();
                            activitySelectize.items = [];
                            
                            console.log('Update placeholder activity select');
                        }
                        
                        activitySelectize.clearOptions();
                    },
                    error: function () {
                        callback();
                        console.log('error', 'Activity select');
                    },
                    success: function (res) {
                        activitySelectize.clearOptions();
                        callback(res);
                        console.log('Success', 'Activity select');
                    }
                });
            });
            
        }
    });
    
    activitySelect = $activitySelect.selectize(activityOptions);
    destinationSelect = $destinationSelect.selectize(destinationOptions);
    
    activitySelectize = activitySelect[0].selectize;
    destinationSelectize = destinationSelect[0].selectize;
    
    destinationValue = $destinationSelect.data('selectizeValue');
    activityValue = $activitySelect.data('selectizeValue');
    
    if (destinationValue) {
        destinationSelectize.addItem(destinationValue);
    } else if (activityValue) {
        activitySelectize.settings.placeholder = activitySelectize.options[activityValue].title;
        activitySelectize.updatePlaceholder();
    }
    
    searchFormButton.on('click', function (evt) {
        var url = $(this).data('action');
        if (url) {
            window.location = url;
        }
    });
    

    function onItemRemove(value) {
        console.log('Item remove', value);
        this.removeItem(value, true);
        this.clearOptions();
        this.refreshOptions(true);
        this.close();
    }
    
    
})();